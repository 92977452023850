<template>
  <div class="dashboard">

      <el-row :gutter="20" class="mb-20">
          <el-col :span="6" v-for="(item, index) in headerData" :key="index">
            <el-card>
                <div class="item-card-data">
                    <div class="grid-cont">
                        <p class="grid-num">{{item.val}}</p>
                        <p>{{ item.name }}</p>
                    </div>
                </div>
            </el-card>
          </el-col>
      </el-row>

      <el-row :gutter="20" class="mb-20">

          <el-col :span="12">
            <el-card>
                <div id="myChart"
                    :style="{ width: '100%', height: '387px' }"></div>
            </el-card>
          </el-col>

          <el-col :span="12">

            <el-card class="mb-20">
                <div class="credits-card">
                    <div class="credits-card-text">
                        <p>To Buy more Credits for issues</p>
                        <el-button type="primary">Top Up</el-button>
                    </div>
                    <div class="credits-card-img">
                        <img src="/image/test-image.jpg" alt="">
                    </div>
                </div>
            </el-card>

            <el-card>
                <template #header>
                    <div class="card-header">
                        <span>Remaining issues in branchs</span>
                    </div>
                </template>

                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-row :gutter="20">
                            <el-col :span="12">HD:</el-col>
                            <el-col :span="12">100</el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">Branch A:</el-col>
                            <el-col :span="12">10</el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">Branch B:</el-col>
                            <el-col :span="12">20</el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">Branch C:</el-col>
                            <el-col :span="12">30</el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">Branch D:</el-col>
                            <el-col :span="12">40</el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="12">
                        <el-button type="primary" style="position: absolute;right: 10px;bottom: 0px;">Manage</el-button>
                    </el-col>
                </el-row>

            </el-card>

          </el-col>
      </el-row>

      <el-row :gutter="20">
          <el-col :span="12">
              <el-card>
                <template #header>
                    <div class="card-header">
                        <span>Pending Applications</span>
                        <el-button class="button" type="text">Latest</el-button>
                    </div>
                </template>
                <el-table
                    :data="tableData"
                    style="width: 100%">
                    <el-table-column
                        prop="userName"
                        label="User Name"
                        width="280">
                    </el-table-column>
                    <el-table-column
                        prop="certTopic"
                        label="Cert Topic">
                    </el-table-column>
                    <el-table-column
                        prop="requestTime"
                        label="Request Time">
                    </el-table-column>
                </el-table>
              </el-card>
          </el-col>
          <el-col :span="12">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>Notifications</span>
                        </div>
                    </template>
                    <div class="notifications-list">
                        <ul>
                            <li>#userName accepted  Certificate Number:#certNumber</li>
                             <li>Head Branch has assigned 10 issues to A branch</li>
                             <li>#AccountName has issued 10 #certificateName in #BranchName</li>
                             <li>system announcement</li>
                        </ul>
                    </div>
                </el-card>
          </el-col>
      </el-row>

      
    <el-card class="mt-20">
        <template #header>
            <div class="card-header">
                <span>Recent Issued Certs</span>
            </div>
        </template>
        <el-row :gutter="20">
            <el-col :span="24">
                <el-table
                    :data="tableRecent"
                    style="width: 100%">
                    <el-table-column
                        prop="name"
                        label="Applicant Name"
                        width="280">
                    </el-table-column>
                    <el-table-column
                        prop="number"
                        label="ECert Number">
                    </el-table-column>
                    <el-table-column
                        prop="topic"
                        label="Cert Topic">
                    </el-table-column>
                    <el-table-column
                        prop="date"
                        label="Cert Issue Date">
                    </el-table-column>
                    <el-table-column
                        prop="branch"
                        label="Campus/Branch">
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        label="Status">
                    </el-table-column>
                    <el-table-column
                        prop="time"
                        label="Approved Time">
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </el-card>

  </div>
</template>

<script>
export default {
    data(){
        return {
            headerData: [{
                name: 'Issued ECertss',
                val: '351'
            },{
                name: 'Remaining ecerts in Account',
                val: '600'
            },{
                name: 'Remaining Credits',
                val: '21'
            },{
                name: 'Pending eCert requests',
                val: '51'
            }],
            tableData: [{
                userName: 'Winglee',
                certTopic: 'Text Content',
                requestTime: '2021-05-31'
            },{
                userName: 'Winglee',
                certTopic: 'Text Content',
                requestTime: '2021-05-31'
            },{
                userName: 'Winglee',
                certTopic: 'Text Content',
                requestTime: '2021-05-31'
            },{
                userName: 'Winglee',
                certTopic: 'Text Content',
                requestTime: '2021-05-31'
            }],
            tableRecent: [{
                name: 'Lorem ipsum dolor',
                number: 'HKUS20160114',
                topic: 'Lorem ipsum dolor',
                date: 'xx/xx/xxxx',
                branch: 'Lorem ipsum',
                status: 'Approved',
                time: 'xx/xx/xxxx xx:xx'
            },{
                name: 'Lorem ipsum dolor',
                number: 'HKUS20160114',
                topic: 'Lorem ipsum dolor',
                date: 'xx/xx/xxxx',
                branch: 'Lorem ipsum',
                status: 'Approved',
                time: 'xx/xx/xxxx xx:xx'
            },{
                name: 'Lorem ipsum dolor',
                number: 'HKUS20160114',
                topic: 'Lorem ipsum dolor',
                date: 'xx/xx/xxxx',
                branch: 'Lorem ipsum',
                status: 'Approved',
                time: 'xx/xx/xxxx xx:xx'
            },{
                name: 'Lorem ipsum dolor',
                number: 'HKUS20160114',
                topic: 'Lorem ipsum dolor',
                date: 'xx/xx/xxxx',
                branch: 'Lorem ipsum',
                status: 'Approved',
                time: 'xx/xx/xxxx xx:xx'
            }]
        }
    },
    mounted() {
    //this.$root => app
    let myChart = this.$root.echarts.init(
      document.getElementById("myChart")
    );
    // 绘制图表
    myChart.setOption({
        title: { text: "ECert issue Report" },
        tooltip: {},
        xAxis: {
            data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        },
        yAxis: {},
        series: [
            {
            name: "Amount",
            type: "bar",
            data: [5, 20, 36, 10, 10, 20, 15, 6, 21, 35, 12, 9],
            },
        ],
      });
    },
    components:{
    },
    methods: {
      handleClick(row) {
        console.log(row);
      }
    }
}
</script>

<style>
.item-card-data{
    height: 150px;
    width: 100%;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}
.item-card-data .grid-cont{
    text-align: center;
}
.item-card-data .grid-num{
    font-size: 30px;
    font-weight: 700;
    color: #2d8cf0;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.credits-card{
    display: inline-flex;
    align-items: center;
}

.credits-card-text{
    width: 100%;
}
.credits-card-text p{
    margin-bottom: 20px;
}
.credits-card-img img{
    width: 100%;
    height: auto;
}
.notifications-list{
    padding: 10px 50px;
}
.notifications-list ul li{
    line-height: 35px;
    margin-bottom: 15px;
}
</style>